/*
 * @Date: 2021-07-28 19:45:15
 * @LastEditors: linjituan
 * @LastEditTime: 2022-03-16 14:49:50
 */

const env = process.env.NODE_ENV === 'production'

const domain = {
  aliyuncs: ''
}
if (env === 'production') {
  domain.aliyuncs = 'https://cloudfile.revolution.auto/'
  domain.aliyuncsImg = 'https://cloudfile.revolution.auto/picture/'
} else {
  domain.aliyuncs = 'https://cloudfile.revolution.auto/'
  domain.aliyuncsImg = 'https://cloudfile.revolution.auto/picture/'
}

export default domain
