<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="名称" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入名称" allow-clear />
              </a-form-item>
            </a-col>
            <!-- <a-col :md="8" :sm="24">
              <a-form-item label="父类ID" prop="parentId">
                <a-input v-model="queryParam.parentId" placeholder="请输入父类ID" allow-clear />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="价格 单位:元" prop="price">
                  <a-input v-model="queryParam.price" placeholder="请输入价格 单位:元" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="租户代码" prop="tenantCode">
                  <a-input v-model="queryParam.tenantCode" placeholder="请输入租户代码" allow-clear />
                </a-form-item>
              </a-col> -->
            <!-- <a-col :md="8" :sm="24">
                <a-form-item label="是否删除" prop="status">
                  <a-select placeholder="请选择是否删除" v-model="queryParam.status" style="width: 100%" allow-clear>
                    <a-select-option v-for="(item, index) in statusOptions" :key="index" :value="item.dictValue">{{
                      item.dictLabel
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col> -->
            <!-- </template> -->
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <!-- <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a> -->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <a-button
            type="primary"
            @click="$refs.createForm.handleAdd()"
            v-hasPermi="['tenant:recyclable:recyclingKind:add']"
          >
            <a-icon type="plus" />新增一级分类
          </a-button>
          <a-button type="primary" @click="toEditLog"> <a-icon type="info-circle" />查看修改记录 </a-button>
          <a-button
            type="primary"
            :disabled="single"
            @click="$refs.createForm.handleUpdate(undefined, ids)"
            v-hasPermi="['tenant:recyclable:recyclingKind:edit']"
          >
            <a-icon type="edit" />修改
          </a-button>
          <a-button
            type="danger"
            :disabled="multiple"
            @click="handleDelete"
            v-hasPermi="['tenant:recyclable:recyclingKind:remove']"
          >
            <a-icon type="delete" />删除
          </a-button>
          <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:recyclable:recyclingKind:export']">
            <a-icon type="download" />导出
          </a-button>
          <!-- <a-button
            type="dashed"
            shape="circle"
            :loading="loading"
            :style="{ float: 'right' }"
            icon="reload"
            @click="getList"
          /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" :parentList="dataList" :statusOptions="statusOptions" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="dataList"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
      >
        <span slot="icon" slot-scope="text, record">
          <img :src="domain.aliyuncsImg + record.icon" alt="" />
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-if="record.index !== 0 || !record.isEnd" />
          <a @click="handleUp(record, undefined)" v-if="record.index !== 0"> <a-icon type="arrow-up" />上移 </a>
          <a @click="handleDown(record, undefined)" v-if="!record.isEnd"> <a-icon type="arrow-down" />下移 </a>
          <a-divider type="vertical" v-if="record.parentId === 0" />
          <a @click="$refs.createForm.handleRowAdd(record, undefined)" v-if="record.parentId === 0">
            <a-icon type="plus" />新增
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:recyclable:recyclingKind:edit']" />
          <a
            @click="$refs.createForm.handleUpdate(record, undefined)"
            v-hasPermi="['tenant:recyclable:recyclingKind:edit']"
          >
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:recyclable:recyclingKind:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['tenant:recyclable:recyclingKind:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {
  listRecyclingKind,
  delRecyclingKind,
  exportRecyclingKind,
  adjustorder,
  childrenList
} from '@/api/recyclable/recyclingKind'
import CreateForm from './modules/CreateForm'
import domain from '@/utils/domain'

export default {
  name: 'RecyclingKind',
  components: {
    CreateForm
  },
  data() {
    return {
      list: [],
      dataList: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        name: null,
        parentId: 0,
        price: null,
        icon: null,
        tenantCode: null,
        status: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: 'ID',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '父类ID',
        //   dataIndex: 'parentId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '站点-价格(元/KG)',
          dataIndex: 'price',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            return record.price || '-'
          }
        },
        {
          title: '上门-价格(元/KG)',
          dataIndex: 'price2',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            return record.price2 || '-'
          }
        },
        {
          title: '自主设置-价格(元/KG)',
          dataIndex: 'price',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            if (record.maxPrice) {
              return (record.minPrice || 0) + '~' + record.maxPrice
            }
            return '-'
          }
        },
        {
          title: '上门-价格(元/KG)',
          dataIndex: 'price2',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '自主设置-价格(元/KG)',
          dataIndex: 'defaultPrice',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            if (record.selfSettingPrice) {
              const selfSettingPrice = JSON.parse(record.selfSettingPrice)
              if (selfSettingPrice.maxPrice) {
                return (selfSettingPrice.minPrice || 0) + '~' + selfSettingPrice.maxPrice
              }
              return ''
            }
            return ''
          }
        },
        {
          title: '是否删除',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            return this.optionsFormat(this.statusOptions, record.status)
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      statusOptions: [
        { dictLabel: '正常', dictValue: '0' },
        { dictLabel: '删除', dictValue: '1' }
      ],
      domain
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询回收物种类列表 */
    getList() {
      this.loading = true
      listRecyclingKind(this.queryParam).then(async (response) => {
        const list = response.rows
        this.list = []
        let i = 0
        for (const item of list) {
          if (item.parentId === 0) {
            item.index = i
            const child = await this.childrenList(item)
            if (child && child.length > 0) {
              child[0].index = 0
              child[child.length - 1].isEnd = true
              item.children = child
            }
            this.list.push(item)
            i++
          }
        }
        this.dataList = this.list
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        name: undefined,
        parentId: undefined,
        price: undefined,
        icon: undefined,
        tenantCode: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.names = this.selectedRows.map((item) => item.name)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      const name = row.name || this.names
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中为' + name + '的数据',
        onOk() {
          return delRecyclingKind(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportRecyclingKind(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    },
    optionsFormat(options, value) {
      return this.selectDictLabel(options, value)
    },
    upGo(fieldData, index) {
      if (index !== 0) {
        const isEnd = fieldData[index].isEnd
        const tIndex = fieldData[index].index
        fieldData[index].isEnd = fieldData[index - 1].isEnd
        fieldData[index].index = fieldData[index - 1].index
        fieldData[index - 1].isEnd = isEnd
        fieldData[index - 1].index = tIndex
        fieldData[index] = fieldData.splice(index - 1, 1, fieldData[index])[0]
      } else {
        fieldData.push(fieldData.shift())
      }
      return fieldData
    },
    downGo(fieldData, index) {
      if (index !== fieldData.length - 1) {
        const isEnd = fieldData[index].isEnd
        const tIndex = fieldData[index].index
        fieldData[index].isEnd = fieldData[index + 1].isEnd
        fieldData[index].index = fieldData[index + 1].index
        fieldData[index + 1].isEnd = isEnd
        fieldData[index + 1].index = tIndex
        fieldData[index] = fieldData.splice(index + 1, 1, fieldData[index])[0]
      } else {
        fieldData.unshift(fieldData.splice(index, 1)[0])
      }
      console.log('fieldData', fieldData)

      return fieldData
    },
    handleUpDown(record, callBack) {
      console.log('record', record)
      let index = 0
      let lIndex = 0
      for (const item of this.dataList) {
        if (record.parentId === 0 && record.id === item.id) {
          this.dataList = callBack(this.dataList, index)
          return
        } else if (record.parentId !== 0 && record.parentId === item.id) {
          let cIndex = 0
          for (const cItem of item.children) {
            if (record.id === cItem.id) {
              this.dataList[lIndex].children = callBack(item.children, cIndex)
              console.log('this.dataList', this.dataList)
              console.log('lIndex', lIndex)
              this.$forceUpdate()
              return
            }
            cIndex++
          }
        }
        if (item.parentId === 0) {
          lIndex++
        }
        index++
      }
    },
    handleDown(record) {
      this.handleUpDown(record, this.downGo)
      this.adjustorder(record, 2)
    },
    handleUp(record) {
      this.handleUpDown(record, this.upGo)
      this.adjustorder(record, 1)
    },
    adjustorder(record, operaType) {
      adjustorder({
        id: record.id,
        operaType: operaType,
        parentId: record.parentId
      }).then((res) => {})
    },
    childrenList(record) {
      return new Promise((resolve, reject) => {
        childrenList(record.id).then((res) => {
          resolve(res.data)
        })
      })
    },
    toEditLog() {
      this.$router.push({
        path: '/recyclable/log/rcoperlog?title=回收物种类'
      })
    }
  }
}
</script>
